import {
  ENV_DEV,
  ENV_PRODUCTION,
  ENV_STAGING,
} from 'src/constants/environments';
import { FEATURE_HIRER_ADMIN_PRIMARY_CONTACT } from 'src/modules/FeatureToggler/constants';
import type { FeatureConfig } from 'src/modules/FeatureToggler/types';

export const hirerAdminPrimaryContact: FeatureConfig = {
  name: FEATURE_HIRER_ADMIN_PRIMARY_CONTACT,
  value: {
    [ENV_DEV]: {
      enabled: true,
    },
    [ENV_STAGING]: {
      enabled: true,
    },
    [ENV_PRODUCTION]: {
      enabled: false,
    },
  },
  toFeature({ environment }) {
    const dict = this.value! as any;
    const val = dict[environment];

    return {
      name: this.name,
      enabled: Boolean(val.enabled),
    };
  },
};
